// React imports
import React, { useState } from 'react';

// gatsby imports
import { Link } from 'gatsby';

// UI - style imports
import { WithStyles, withStyles } from '@material-ui/core';
import { AnimateSharedLayout, motion } from 'framer-motion';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './ToggleMenu.style';

type ToggleMenuProps = {
    className?: string;
};

const variants = {
  open: {
    opacity: 1,
    display: 'flex',
    transition: { ease: 'easeIn', duration: 0.5 },
  },
  closed: {
    opacity: 0,
    transition: { ease: 'easeOut', duration: 0.5 },
    transitionEnd: { display: 'none' },
  },
};

function ToggleMenu (props: ToggleMenuProps & WithStyles<typeof styles>) {
    const { classes, className } = props;
    const [showSubMenu, setShowSubMenu] = useState(false);

    return (
      <Box className={clsx(classes.root, className)}>
          <ul id='menu'>
            <li>
              <Link to='/tarjetas'>Tarjetas</Link>
            </li>
            <li>
              <Link to='/travesia'>Travesía</Link>
            </li>

            <li onClick= {() => setShowSubMenu(!showSubMenu)}>
              <span
                className={clsx(showSubMenu && 'darkerText', classes.submenu)}
              >
                Legales
              </span>

              <motion.div
                layout
                initial={false}
                animate={showSubMenu ? "open" : "closed"}
                variants={variants}
              >
                <ul id='subMenu'>
                  <li id='dotedLine'>
                    <Link to='/aviso-de-privacidad'>Aviso de privacidad</Link>
                  </li>
                  <li id='dotedLine'>
                    <Link to='/terminos-y-condiciones'>Términos y condiciones</Link>
                  </li>
                  <li id='last'>
                    <Link to='/contrato-credito'>Contrato de apertura de crédito</Link>
                  </li>
                </ul>
              </motion.div>
            </li>
          </ul>
        </Box>
    );
}

export default withStyles(styles)(ToggleMenu);
