import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) => createStyles({
    root: {
      fontFamily: 'MuseoSans, sans-serif',
      fontWeight: 300,
      fontSize: '40px',
      color: '#ffffff',
      position: 'absolute',
      transition: 'width .5s',
      top: '-4px',
      width: '101vw',
      height: '100vh',
      marginLeft: '-60px',
      marginTop: '-30px',
      backgroundColor: theme.palette.primary.dark,
      '& .darkerText': {
          color: theme.palette.primary.main,
      },
      '& #menu': {
        marginLeft: '30%',
        marginTop: '15%',
        listStyleType: 'none',
        [theme.breakpoints.down(500)]: { width: '100%', },
      },
      '& #menu li': {
        borderBottom: '1px solid #fff',
        lineHeight: '2',
        letterSpacing: '1.95px',
        marginRight: '40%',
        marginBottom: '0px',
        [theme.breakpoints.down(650)]: { letterSpacing: '0.5px', },
      },
      '& #menu a': {
        textDecoration: 'none',
        color: '#ffffff',
      },
      '& #subMenu': {
        marginLeft: '40%',
        listStyleType: 'none',
        fontSize: '25px',
        marginTop: '-50px',
        [theme.breakpoints.down(1350)]: {
          marginTop: '-20px',
          fontSize: '20px',
        },
        [theme.breakpoints.down(650)]: {
          marginTop: '0px',
          marginLeft: '7%',
          fontSize: '17px',
        },
      },
      '& #subMenu li': {
        padding: '10px 0 10px',
        marginRight: '0%',
        borderBottom: 'none',
        lineHeight: '1.5',
      },
      '& #subMenu #dotedLine': {
        borderBottom: '1px dashed #fff',
        lineHeight: '2',
      },
      '& a': {
        textDecoration: 'none',
        color: '#ffffff',
      },
      '& #last': {
        marginBottom: '30px !important',
      },
      [theme.breakpoints.down(1200)]: { marginTop: '-40px', },
      [theme.breakpoints.down(650)]: { width: '116vw', },
      [theme.breakpoints.down(500)]: {
        marginTop: '0px', 
        '& #menu': { marginLeft: '18%', },
      },
    },
    submenu: { cursor: 'pointer' },
});
