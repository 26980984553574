// React imports
import React, { useState } from 'react';

// UI - style imports
import {
    WithStyles,
    withStyles,
    useMediaQuery
} from '@material-ui/core';
import { motion } from 'framer-motion';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@cuenca-mx/tamayo/dist/Button';

// local imports
import { styles } from './NavigationBar.style';
import ToggleMenu from 'views/MenuBar/ToggleMenu';

const variants = {
    open: {
        opacity: 1,
        x: 0,
        display: 'flex',
        transition: {
            duration: 0.5,
            ease: 'easeIn',
        },
    },
    closed: {
        opacity: 0,
        x: '-100%',
        transition: {
            duration: 0.5,
            ease: 'easeOut',
        },
        transitionEnd: { display: 'none' },
    },
};

function NavigatorBar (
    props: WithStyles<typeof styles>
) {
    const { classes } = props;
    const [isMenu, setIsMenu] = useState(false);
    const showButton = useMediaQuery('(min-width:1350px)');

    return (
        <Box className={classes.root}>
            <SvgIcon id='hamb' viewBox="0 0 35 24" onClick= {() => setIsMenu(!isMenu)} >
              <g id="Welcome" strokeWidth="1" fillRule="evenodd">
                  <g id="Desktop-HD_Tarjetas_Cuenca_HOME" transform="translate(-59.000000, -188.000000)">
                      <g id="elementos_fijo">
                          <g id="hamburguesa" transform="translate(59.398400, 189.566800)">
                              <line x1="34.555" y1="0.5" x2="-3.55271368e-15" y2="0.5" id="Stroke-2607" strokeWidth="3.44"></line>
                              <polyline id="Stroke-2608" strokeWidth="3.437" points="34.555 10.7656 21.3944541 10.7656 14.4456894 10.7656 -3.55271368e-15 10.7656"></polyline>
                              <line x1="34.555" y1="20.6894" x2="-3.55271368e-15" y2="20.6894" id="Stroke-2609" strokeWidth="3.437"></line>
                          </g>
                      </g>
                  </g>
              </g>
            </SvgIcon>
            <p>{isMenu}</p>
            {isMenu &&
            (<SvgIcon id='closeButton' viewBox="0 0 39 39" onClick= {() => setIsMenu(!isMenu)} >
              <g id="Welcome" strokeWidth="1" fillRule="evenodd">
                  <g id="closeButton" transform="translate(-1335.000000, -55.000000)" strokeWidth="5">
                      <g id="boton-cerrar" transform="translate(1338.100000, 58.000000)">
                          <line x1="0.5" y1="32.5" x2="32.1529956" y2="0.847004351" id="Line-Copy"></line>
                          <line x1="0.673502176" y1="0.673502176" x2="32.3264978" y2="32.3264978" id="Line-Copy-2"></line>
                      </g>
                  </g>
              </g>
            </SvgIcon>)}
            <motion.div
                layout
                initial={false}
                animate={isMenu ? "open" : "closed"}
                variants={variants}
            >
                <ToggleMenu />
            </motion.div>
            {/* <ToggleMenu className={isMenu ? 'is-active': null}/> */}

            {showButton && <Button className={isMenu ? 'darker': null} text='IR A CUENCA' link='https://cuenca.com' />}
        </Box>
    );
}

export default withStyles(styles)(NavigatorBar);
