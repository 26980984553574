import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) => createStyles({
    root: {
        fontFamily: 'MuseoSans, sans-serif',
        height: '300px',
        width: '100vw',
        background: '#3D4274',
        position: 'relative',
        zIndex: 1199,
        '& #footer-content': {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            maxWidth: '1400px',
            margin: 'auto',
            justifyContent: 'space-between',
        },
        [theme.breakpoints.up(1050)]: { height: '241px', },
        [theme.breakpoints.down(1050)]: { paddingBottom: '40px' },
        [theme.breakpoints.down(670)]: { height: '250px', },
        [theme.breakpoints.down(500)]: { height: '300px', },
    },
    terms: {
        height: '104px',
        width: '350px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginLeft: '50px',
        marginTop: '90px',
        marginRight: '320px',
        '& .t': {
            color: '#FEFEFE',
            fontSize: '20.7px',
            fontWeight: 700,
            letterSpacing: '1px',
            textDecoration: 'none',
            '&:hover': { color: theme.palette.primary.dark },
            '&:focus': { color: theme.palette.primary.dark },
        },
        [theme.breakpoints.down(1400)]: { marginRight: '200px', },
        [theme.breakpoints.down(1250)]: { marginRight: '100px', },
        [theme.breakpoints.down(1152)]: { marginRight: '5px', },
        [theme.breakpoints.down(1050)]: {
            marginTop: '40px',
            marginBottom: '10px',
            marginLeft: '0',
        },
        [theme.breakpoints.down(670)]: {
            height: '75px',
            marginBottom: '15px',
            '& .t': {
                fontSize: '10.99px',
                letterSpacing: '0.57px',
            },
        },
    },
    multipleButtons: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '2fr',
        gridRowGap: '40px',
        [theme.breakpoints.down(660)]: { gridRowGap: '25px' },
    },
    button: {
        gridColumnStart: 1,
        gridRowStart: 1,
        maxWidth: '448.5px',
        maxHeight: '40px',
        marginTop: '92px',
        [theme.breakpoints.down(1050)]: { marginTop: '35px', },
        [theme.breakpoints.down(980)]: { marginRight: '0', },
        '& svg': { marginLeft: '10px !important' },
        '& span': {
          color: theme.palette.primary.main,
          fontSize:'12px',
          [theme.breakpoints.down(650)]: { fontSize:'8px', },
          [theme.breakpoints.down(500)]: {
            fontSize: '9px',
            letterSpacing: '1.3px',
          },
        },
        [theme.breakpoints.down(650)]: {'& a': {maxWidth: '235px',}},
        [theme.breakpoints.down(500)]: {
          marginTop: '19px',
          '& a': {
            maxWidth: '350px',
            width: '165%',
          },
        },
    },
    socialText: {
      textAlign: 'right',
      fontSize: '12px',
      fontWeight: 700,
      letterSpacing: '1.5px',
      color: theme.palette.primary.gray,
      marginBottom: '1rem',
      [theme.breakpoints.down(500)]: {
        textAlign: 'left',
        letterSpacing: '1.6px',
        marginBottom: '1.4rem',
        fontSize: '11px',
      }
    },
    social: {
        height: 'auto',
        minWidth: '191px',
        gridRowGap: '23px',
        marginTop: '96px',
        marginRight: '20px',
        justifyItems: 'flex-end',
        '& #copy': {
            gridColumnStart: 1,
            gridRowStart: 2,
            justifySelf: 'end',
            alignSelf: 'end',
            textAlign: 'right',
            marginBottom: 0,
            fontSize: '13.8px',
            fontWeight: 700,
            color: '#FFFFFF',
            letterSpacing: '2px',
        },
        [theme.breakpoints.down(1400)]: { marginRight: '80px', },
        [theme.breakpoints.down(1050)]: {
            gridRowGap: '74px',
            position: 'absolute',
            margin: 0,
            marginLeft: '20px',
            right: '55px',
            bottom: '70px',
        },
        [theme.breakpoints.down(660)]: {
            gridRowGap: '47px',
            marginLeft: '57px',
            bottom: '54px',
            right: '30px',
            '& #copy': { fontSize: '9.8px' },
        },
        [theme.breakpoints.down(500)]: {
            minWidth: '120px',
            marginLeft: '0px',
            right: '0px',
            left: '30px',
            bottom: '40px',
            '& #copy': {
              fontSize: '8px',
              textAlign: 'left'
            },
        },
    },
    logos: {
        marginBottom:'2rem',
        gridColumnStart: 1,
        gridRowStart: 1,
        height: '20px',
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'row',
        [theme.breakpoints.down(500)]: {
          marginBottom:'1.4rem',
          width: '170px',
        },
        '&:hover': { color: theme.palette.primary.dark },
    },
    leftWrapper: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down(1050)]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginLeft: '57px',
        },
        [theme.breakpoints.down(980)]: { width: '50%', },
        [theme.breakpoints.down(670)]: { marginLeft: '30px', },
    },
});
