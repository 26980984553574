// React imports
import React from 'react';

// gatbsy imports
import { Link } from 'gatsby';

// Tamayo imports
import Button from '@cuenca-mx/tamayo/dist/Button';

// UI - style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './TopBar.style';
import cuencaCardsLogo from 'images/cuenca_cards_logo.svg';


function TopBar (props: WithStyles<typeof styles>) {
    const { classes } = props;
    const showButton = !useMediaQuery('(min-width:1350px)');

    return (
      <Box className={classes.root}>
          <Link to='/'>
              <img src={cuencaCardsLogo} alt='Logo Cuenca' />
          </Link>

           {showButton && <Button text='IR A CUENCA' link='https://cuenca.com' />}
      </Box>
    );
}

export default withStyles(styles)(TopBar);
