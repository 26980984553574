import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        zIndex: theme.zIndex.drawer,
        maxWidth: '240px',
        marginLeft: '59px',
        marginTop: '-20px',
        '& .is-active': {
            width: '100vw',
            fontSize: '40px',
        },
        '& #hamb': {
            height: '22px',
            width: '35px',
            cursor: 'pointer',
            stroke: theme.palette.primary.light,
            marginBottom: '24px',
            '&:hover': {
              stroke: theme.palette.primary.buttonHover + '!important',
            },
        },
        '& #closeButton': {
            position:'absolute',
            bottom: '26vh',
            right: '-73vw',
            width: '33px',
            cursor: 'pointer',
            stroke: theme.palette.primary.dark,
            [theme.breakpoints.down(1350)]: {
              bottom: '0vh',
              stroke: '#ffffff',
              zIndex: 1,
              height: '39px',
            },
            [theme.breakpoints.down(650)]: {
              right: '-80vw',
            },
        },
        '& #cuencaButton': {
            marginBottom: '33.19px',
            marginTop: '16px',
            maxWidth: '235.5px',
            '&:hover': {
              backgroundColor: theme.palette.primary.buttonHover,
              boxShadow: 'none',
            },
        },
        '& .darker': {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down(1350)]: {
            '& #hamb': { marginBottom: '55px', },
        },
        [theme.breakpoints.down(1200)]: {
            '& #cuencaButton': {
                position: 'absolute',
                top: '3em',
                right: '5em',
            },
        },
        [theme.breakpoints.down(1000)]: {
            '& #hamb': { marginBottom: '40px', },
        },
        [theme.breakpoints.down(650)]: {
            marginLeft: '35px',
            marginTop: '-20px',
            '& #hamb': { marginBottom: '40px', },
            '& #cuencaButton': { top: '3em', },
        },
        [theme.breakpoints.down(501)]: {
            marginLeft: '25px',
            marginTop: '-40px',
            '& #hamb': { width: '30px', },
            '& #cuencaButton': {
                top: '2.5em',
                right: '25px',
            },
        },
        [theme.breakpoints.down(350)]: {
            '& #cuencaButton': { right: '15px', },
        },
    },
    socialIconsContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '200px',
        [theme.breakpoints.down(1350)]: { height: '320px', },
        [theme.breakpoints.down(650)]: { height: '205px', },
    },
    '@media (max-height: 500px)': {
        root: {
            '& #hamb': {
                width: '25px',
                marginBottom: '25px',
            },
            '& #cuencaButton': { top: 0, },
        },
    },
});
