// React import
import React from 'react';

// utils imports
import { Helmet } from 'react-helmet';

// UI imports
import Box from '@material-ui/core/Box';
import CookieConsent, { Cookies } from 'react-cookie-consent';

// layout components
import Footer from 'views/Footer';
import NavigationBar from 'views/MenuBar/NavigationBar';
import TopBar from 'views/MenuBar/TopBar';

// local imports
import 'fonts/museoSans.css';
import './Layout.style.css';

type LayoutProps = {
    children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {

    return (
        <>
            <Helmet>
                <meta name="facebook-domain-verification" content="vpyky4b1fic9cueoew02krusjh94tx" />
            </Helmet>

            <TopBar />

            <NavigationBar />

            <Box id='layout-content'>
                { children }
            </Box>

            <CookieConsent
                acceptOnScroll
                cookieSecurity
                acceptOnScrollPercentage={3}
                buttonText='Acepto'
                cookieName='tarjetascuenca-facebook-pixel'
                sameSite='none'
                onAccept={() => {
                    Cookies.set('tarjetascuenca-google-analytics', true);
                    if (typeof window !== 'undefined') {
                        if (typeof window.fbq === 'function') {
                            window.fbq('init', '436171176938956');
                            window.fbq('track', 'PageView');
                        }
                    }
                }}
                style={{
                    backgroundColor: '#E8E9EE',
                    boxShadow: '0 0 2px 1px #F0F1F4',
                    color: '#3D4274',
                    textAlign: 'center',
                }}
                buttonStyle={{
                    background: 'none',
                    border: 'none',
                    color: '#89BCE7',
                    fontSize: '14.5px',
                    fontWeight: 900,
                }}
            >
                Este sitio utiliza cookies para mejorar la experiencia de usuario. Si continua navegando, consideramos que acepta su uso.
            </CookieConsent>

            <Footer />
        </>
    );
};

export default Layout;
