// React imports
import React from 'react';

// gatsby imports
import { Link } from 'gatsby';

// UI – style imports
import Button from '@cuenca-mx/tamayo/dist/Button';
import SocialIcon from '@cuenca-mx/tamayo/dist/SocialIcon';
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';

// local imports
import { styles } from './Footer.style';

function Footer(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const iconSize = useMediaQuery('(min-width:1050px)') ? 'small' : 'smol';

    return (
        <Box className={classes.root}>
            <Box id="footer-content">
                <Box className={classes.leftWrapper}>
                    <Box className={classes.terms}>
                        <Link to="/terminos-y-condiciones" className="t">Términos y condiciones</Link>
                        <Link to="/aviso-de-privacidad" className="t">Aviso de privacidad</Link>
                        <Link to="/contrato-credito" className="t">Contrato de apertura de crédito</Link>
                    </Box>

                    <Box className={classes.multipleButtons}>
                        <Box className={classes.button}>
                            <Button whatsapp text="Centro de soporte de Cuenca" link="https://wa.me/525513285364" />
                        </Box>
                    </Box>
                </Box>

                <Box className={classes.social}>
                    <p className={classes.socialText}>REDES SOCIALES CUENCA</p>
                    <Box className={classes.logos}>
                        <SocialIcon size={iconSize} social="facebook" link="https://www.facebook.com/cuencamexico" />
                        <SocialIcon size={iconSize} social="instagram" link="https://www.instagram.com/cuenca" />
                        <SocialIcon size={iconSize} social="twitter" link="https://www.twitter.com/cuenca_mx" />
                        <SocialIcon size={iconSize} social="tiktok" link="https://vm.tiktok.com/JdbEhaU" />
                        <SocialIcon size={iconSize} social="github" link="https://www.github.com/cuenca-mx" />
                    </Box>

                    <p id="copy">
                        ©TARJETAS CUENCA, {new Date().getFullYear()}
                    </p>
                </Box>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Footer);
